<template>
  <v-app>
    <AppSnack ref="snack" :options="alertOptions" :show="showSnack" />
    <router-view />
  </v-app>
</template>

<script>
import AppSnack from '@/components/core/AppSnack.vue';
import { EventBus } from '@/event-bus';
import { mapActions } from 'vuex';

export default {
  name: 'CoreLayout',
  components: { AppSnack },

  async mounted() {
    await this.init();
  },

  watch: {
    $route: function (route, previousRoute) {
      const queryStrings = [];
      for (const key in previousRoute.query) {
        queryStrings.push(`${key}:${previousRoute.query[key]}`);
      }

      const redirect = `${previousRoute.fullPath}${queryStrings.join('&')}`;
      this.setRedirectURL(redirect);
    }
  },

  created() {
    EventBus.$on('SHOW_SNACK', (options) => {
      this.alertOptions = options;
      if (this.$refs.snack) this.$refs.snack.show();
    });
  },

  data: () => ({
    alertOptions: {},
    showSnack: false
  }),

  methods: {
    ...mapActions('authModule', ['setRedirectURL']),
    ...mapActions('coreModule', ['init'])
  }
};
</script>
