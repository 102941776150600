<template>
  <v-snackbar
    v-model="showModel"
    :color="options.color"
    :multi-line="true"
    :timeout="options.timeout"
    top
    right
  >
    <template v-slot:action="{ attrs }">
      <v-icon @click="showModel = false" v-bind="attrs">mdi-close</v-icon>
    </template>
    <b v-if="options.title">{{ options.title }}</b>
    <p class="mb-0" v-html="options.message"></p>
  </v-snackbar>
</template>

<script>
const defaultOptions = {
  vertical: true,
  position: '',
  color: 'primary',
  title: '',
  right: true,
  bottom: false,
  top: true,
  left: false,
  message: '',
  timeout: 5000
};

export default {
  name: 'AppSnack',
  props: {
    options: {
      type: Object,
      default: function () {
        return defaultOptions;
      }
    }
  },

  data: () => ({
    showModel: false
  }),

  created() {
    this.$watch(
      'options',
      (cur) => {
        for (const k in defaultOptions) {
          if (!cur[k] && typeof cur[k] !== 'boolean')
            cur[k] = defaultOptions[k];
        }
      },
      { immediate: true }
    );
  },

  methods: {
    show() {
      this.showModel = true;
    }
  }
};
</script>